import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import Color from 'color';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import PagePaddingTop from '../components/page-padding-top';
import VariableFontTitle from '../components/variable-font-title';
import Box from '../components/box';
import Content from '../components/content.js';
import BlockContent from '../components/block-content.js';
import TitleCardsPill from '../components/title-cards-pill';
import Card from '../components/card';
import Grid from '../components/grid';
import HorizontalRule from '../components/horizontal-rule';
import SharePanel from '../components/share-panel';
import ProgressBarTenet from '../components/progress-bar-tenet';
import { goodColors } from '../components/colors';

export default function Tenet({ data }) {
  const [footnotes, setFootnotes] = useState([]);
  const contentRef = useRef(null);

  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    let footnotes = data.tenet.content
      .filter((item) => item._type === 'complexBlockWrapper')
      .map((item) => item._rawText.filter((item) => item.markDefs.find((item) => item._type === 'footnote')))
      .flat()
      .flatMap((item) => item.markDefs)
    ;
    setFootnotes(footnotes);
  }, data.tenet);

  const handleScroll = () => {
    const y = window.pageYOffset;

    if (y > 0) {
      theme.setHeaderHide(true);
    } else {
      theme.setHeaderHide(false);
    }
  };

  return (
    <PagePaddingTop
      css={css`
        background-image: linear-gradient(
          180deg,
          ${data.tenet.color.value} 0%,
          ${Color(data.tenet.color.value).lighten(0.35).hex()} 100vh,
          ${Color(data.tenet.color.value).lighten(0.35).hex()} calc(100% - 100vh),
          ${data.tenet.color.value} 100%
        );
        padding-bottom: 0.1px;
      `}
    >
      <SEO
        title={data.tenet.title}
        description={
          data.tenet.content.length > 0
            ? data.tenet.content
                .filter((el) => el._type === 'complexBlockWrapper')[0]
                ._rawText.map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
      />
      <ProgressBarTenet
        leftText="Home"
        leftURL="/"
        centerIndex={
          data.home.tenets.findIndex((tenet) => tenet.slug.current === data.tenet.slug.current) + 1
        }
        centerTotal={data.home.tenets.length}
        centerText={data.tenet.title}
        centerInfoCaptionCard={false}
        rightURL={`/tenet/${
          data.home.tenets[
            (data.home.tenets.findIndex((tenet) => tenet.slug.current === data.tenet.slug.current) +
              1) %
              data.home.tenets.length
          ].slug.current
        }`}
        progressRef={contentRef}
        barColor={data.tenet.color.value}
      />
      <Box x={4}> 
        <VariableFontTitle
          css={css`
            margin-bottom: var(--margin9);

            @media (max-width: 800px) {
              margin-bottom: var(--margin7);
            }

            @media (max-width: 550px) {
              margin-bottom: var(--margin5);
            }
          `}
          text={data.tenet.title}
          stenciling={data.tenet.type?.stenciling}
          weight={data.tenet.type?.weight}
          size={data.tenet.type?.size}
          alignment={data.tenet.type?.alignment}
        />
      </Box>
      <div ref={contentRef}>
        <Content blocks={data.tenet.content} />
      </div>
      {footnotes.length > 0 &&<Box
        x={10}
        y={12}
        css={css`
          margin-top: 0 !important;
        `}
      >
        <Grid columns={8}>
          <ol>
            {footnotes.map((footnote, i) => <li id={`footnote-${i + 1}`}><BlockContent content={footnote.text} /></li>)}
          </ol>
        </Grid>
      </Box>}
      {(data.tenet.relatedProjects.length > 0 && data.tenet.relatedArticles.length > 0) && <Box>
        <HorizontalRule />
      </Box>}
      {data.tenet.relatedProjects.length > 0 && (
        <>
          <Box x={10} y={9}>
            <TitleCardsPill 
              heading="Related Work" 
              pillText="All Work" 
              pillLink="/work"
              columns={3}
            >
              {data.tenet.relatedProjects.map((project) => (
                <Card
                  url={`/project/${project.slug.current}`}
                  image={project.featuredImage}
                  imageCrop={false}
                  monoText={project.discipline.map((item) => item.title).join(', ')}
                  workRatio={project.featuredImage?.asset.metadata.dimensions.aspectRatio}
                  text={project.title}
                />
              ))}
            </TitleCardsPill>
          </Box>
          {data.tenet.relatedArticles.length > 0 && <Box>
            <HorizontalRule />
          </Box>}
        </>
      )}
      {data.tenet.relatedArticles.length > 0 && (
        <Box x={10} y={9}>
          <TitleCardsPill 
            heading="Related Writing" 
            pillText="All Writing" 
            pillLink="/news"
            columns={3}
          >
            {data.tenet.relatedArticles.map((article) => (
              <Card
                url={article.url ? article.url : `/article/${article.slug.current}`}
                external={article.externalArticle}
                externalTitle={article.publication}
                image={article.featuredImage}
                imageCrop={true}
                imageOverlay={(article.externalArticle) ? Color(goodColors[article.title.length % goodColors.length]).lighten(0.3).hex() : false}
                monoText={`${article.tag
                  .map((tag) => tag.title)
                  .sort()
                  .join(', ')} — ${format(new Date(article.datetime), 'MMMM d, yyyy')}`}
                text={article.title}
              />
            ))}
          </TitleCardsPill>
        </Box>
      )}
      <SharePanel
        shareText="Share"
        saveText="Next Tenet"
        hoverColor="var(--black)"
        hoverBackground="var(--white)"
        overrideSave={`/tenet/${
          data.home.tenets[
            (data.home.tenets.findIndex((tenet) => tenet.slug.current === data.tenet.slug.current) +
              1) %
              data.home.tenets.length
          ].slug.current
        }`}
      />
    </PagePaddingTop>
  );
}

export const query = graphql`
  query TenetTemplateQuery($slug: String!) {
    tenet: sanityTenet(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      type {
        stenciling
        weight
        size
        alignment
      }
      color {
        value
      }
      content {
        ... on SanityComplexBlockWrapper {
          _key
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          width
        }
        ... on SanityRichImage {
          _key
          _type
          image {
            ...Image
            asset {
              description
              metadata {
                dimensions {
                  aspectRatio
                }
              }
              width
              height
            }
          }
          description
          width
          captionCard {
            hasCard
            _rawCaption(resolveReferences: { maxDepth: 10 })
            linkText
            linkURL
            position
          }
          hotSpots {
            hasHotSpots
            hotSpots {
              images {
                ...Image
                asset {
                  description
                  metadata {
                    dimensions {
                      aspectRatio
                    }
                  }
                }
              }
              x
              y
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          video {
            asset {
              url
            }
          }
          description
          width
          poster {
            ...Image
          }
          autoplay
          muted
          scrubber
          loop
        }
        ... on SanityTripleImage {
          _key
          _type
          portraitImage {
            ...Image
          }
          topLandscapeImage {
            ...Image
          }
          bottomLandscapeImage {
            ...Image
          }
          reverse
          captionCard {
            hasCard
            _rawCaption(resolveReferences: { maxDepth: 10 })
            linkText
            linkURL
            position
          }
        }
        ... on SanityGallery {
          _key
          _type
          media {
            ... on SanityImage {
              _type
              ...Image
              asset {
                description
                metadata {
                  dimensions {
                    aspectRatio
                  }
                }
              }
            }
            ... on SanityVideo {
              _type
              video {
                asset {
                  url
                }
              }
            }
          }
        }
        ... on SanityQuote {
          _type
          _rawText(resolveReferences: { maxDepth: 10 })
          _rawAuthor(resolveReferences: { maxDepth: 10 })
          hideBox
        }
        ... on SanityArticle {
          _id
          _type
          slug {
            current
          }
          datetime
          tag {
            title
          }
          content {
            ... on SanityComplexBlockWrapper {
              _type
              _rawText(resolveReferences: { maxDepth: 10 })
            }
          }
        }
        ... on SanityEvent {
          _key
          _type
          _rawDateTime(resolveReferences: { maxDepth: 10 })
          _rawAdmission(resolveReferences: { maxDepth: 10 })
          _rawLocation(resolveReferences: { maxDepth: 10 })
          _rawAccessibility(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityInformation {
          _key
          _type
          title
          information {
            data
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
        ... on SanityCode {
          _key
          _type
          title
          code
        }
        ... on SanityIframe {
          _key
          _type
          url
          aspectRatio
        }
        ... on SanityLink {
          _type
          text
          url
          _key
        }
      }
      relatedProjects {
        slug {
          current
        }
        title
        featuredImage {
          ...Image
          asset {
            description
            metadata {
              dimensions {
                aspectRatio
              }
            }
          }
        }
        discipline {
          title
        }
      }
      relatedArticles {
        slug {
          current
        }
        title
        externalArticle
        url
        publication
        datetime
        featuredImage {
          ...Image
        }
        tag {
          title
        }
      }
    }
    home: sanityHome(_id: { regex: "/(drafts\\\\.home)?/" }) {
      tenets {
        slug {
          current
        }
      }
    }
  }
`;
