import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Back, Info, Right } from './icons';
import ProgressBarWrapper from './progress-bar-wrapper';
import CaptionCard from './caption-card';

export default function ProgressBarTenet({
  className,
  leftText,
  leftURL,
  centerIndex,
  centerTotal,
  centerText,
  centerInfoCaptionCard,
  rightText,
  rightURL,
  progressRef,
  barColor
}) {
  const [popup, setPopup] = useState(false);

  return (
    <ProgressBarWrapper
      className={className}
      barColor={barColor}
      progressRef={progressRef}
      css={css`
        white-space: nowrap;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @media (max-width: 550px) {
            flex-basis: 13.5rem;
            font-size: 1.5rem !important;
          }
        `}
      >
        {(leftText || leftURL) && (
          <>
            <Back
              css={css`
                margin-top: -0.2rem;
                margin-right: 1.7rem;

                @media (max-width: 550px) {
                  margin-right: 0.7rem;
                }
              `}
              url={leftURL}
              hover={true}
            />
            {leftText}
          </>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          justify-content: center;
          align-items: center;
        `}
      >
        {(centerIndex || centerTotal) && (
          <sup
            className="type--mono-heading"
            css={css`
              margin-right: 1.8rem;
              font-variant-numeric: lnum onum pnum;

              @media (max-width: 800px) {
                top: 0;
                font-family: 'Favorit', sans-serif;
                font-size: 2.4rem !important;
                line-height: 1.2 !important;
                margin-right: 0;
              }
            `}
          >
            {centerIndex}/{centerTotal}
          </sup>
        )}
        <span
          css={css`
            @media (max-width: 800px) {
              display: none;
            }
          `}
        >
          {centerText}
        </span>
        {centerInfoCaptionCard && (
          <>
            <Info
              css={css`
                margin-top: -0.4rem;
                margin-left: 1.1rem;

                @media (max-width: 800px) {
                  display: none;
                }

                svg {
                  width: 2.2rem;
                  height: 2.2rem;
                }
              `}
              hover={true}
              active={popup}
              onClick={() => (popup ? setPopup(false) : setPopup(true))}
            />
            <CaptionCard
              text={centerInfoCaptionCard}
              css={css`
                position: absolute;
                display: ${popup ? 'block' : 'none'};
                top: var(--margin);
                right: var(--gutterHalf);
                white-space: normal;
              `}
            />
          </>
        )}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: flex-end;

          @media (max-width: 550px) {
            flex-basis: 13.5rem;
            font-size: 1.5rem !important;
          }
        `}
      >
        {(rightText || rightURL) && (
          <>
            Next<span
              css={css`
                @media (max-width: 370px) {
                  display: none;
                }
              `}
            >&nbsp;Tenet</span>
            <Right
              css={css`
                margin-top: -0.2rem;
                margin-left: 1.7rem;

                @media (max-width: 550px) {
                  margin-left: 0.7rem;
                }
              `}
              url={rightURL}
              hover={true}
            />
          </>
        )}
      </div>
    </ProgressBarWrapper>
  );
}
